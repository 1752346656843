import styled from 'styled-components'
import { ButtonBase, darken } from '@material-ui/core'
import { mainColor } from '../../gatsby-theme-material-ui-top-layout/theme'

export const Button = styled(ButtonBase)<{ size?: 'normal' | 'large' }>`
    background-color: ${mainColor};
    padding: 12px 36px;

    height: ${(props) => (props.size === 'large' ? '70px' : undefined)};
    color: white;
    font-size: 1.125rem;
    border: none;
    outline: none;
    font-weight: bold;
    border-radius: 100px;
    transition: color 0.2s ease-in-out 0s, background-color 0.2s ease-in-out 0s,
        border 0.2s ease-in-out 0s, opacity 0.2s ease-in-out 0s;
    line-height: 1.5;
    :hover {
        background-color: ${darken(mainColor, 0.1)};
    }
    :disabled {
        background-color: rgb(73, 77, 76);
        opacity: 0.5;
        :hover {
            background-color: rgb(73, 77, 76);
        }
    }
`

export const ButtonBlack = styled(Button)`
    background-color: rgb(27, 34, 43);
    :hover {
        background-color: rgb(73, 77, 76);
    }
`

export const ButtonWhite = styled(Button)`
    color: black;
    background-color: #e0ebed;
    :hover {
        background-color: #fff;
    }
`

const orangeColor = '#F2A177'
export const ButtonOrange = styled(Button)`
    color: black;
    background-color: ${orangeColor};
    :hover {
        background-color: ${darken(orangeColor, 0.2)};
    }
`

export const CircleButtonBlack = styled(ButtonBlack)`
    border-radius: 50%;
    width: 84px;
    height: 84px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
`
export const ButtonPalid = styled(Button)`
    color: rgb(28, 33, 31);
    background-color: rgb(224, 235, 237);
    :hover {
        background-color: rgb(173, 206, 219);
    }
`

export const ButtonWArrow = styled(Button)`
    i {
        margin-left: 4px;
        transition: all 0.3s ease-in-out;
        position: relative;
        left: 0;
    }
    :hover i {
        left: 16px;
    }
`
