import styled from 'styled-components'
import { columnGap, rowGap } from '../utils'
import { sBreakpoints } from '../constants'

export const FRow = styled.div`
    display: flex;
    flex-direction: row;
`

export const FRowG16 = styled(FRow)`
    ${columnGap(16)}
`

export const FRowTColG16 = styled(FRowG16)`
    ${sBreakpoints.tablet} {
        flex-direction: column;
        ${columnGap(0)}
        ${rowGap(16)}
    }
`

export const FRowG128 = styled(FRow)`
    ${columnGap(128)}
`
export const FRowG8 = styled(FRow)`
    ${columnGap(8)}
`

export const FRowG5 = styled(FRow)`
    ${columnGap(5)}
`

export const FCol = styled.div`
    display: flex;
    flex-direction: column;
`

export const FColG16 = styled(FCol)`
    ${rowGap(16)}
`
export const FColG8 = styled(FCol)`
    ${rowGap(8)}
`
export const FExpand = styled.div`
    flex: 1;
`
