import styled from 'styled-components'

export const H2 = styled.h2`
    font-size: calc(28.91px + 1.59vw);

    color: rgb(28, 33, 31);
    line-height: 1.08;
    font-weight: bold;
    letter-spacing: -0.8px;
    margin-bottom: 16px;
`

export const H3 = styled.h3`
    font-size: 1.125rem;
    line-height: 1.44;
    font-weight: 500;
    color: rgb(28, 33, 31);
    margin: 0;
`
export const P = styled.p`
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.44;
    color: rgb(28, 33, 31);
    margin-bottom: 0.15em;
    margin-top: 0.15em;
`

export const InfoSmall = styled.p`
    color: rgb(114, 122, 135);
    font-size: 14px;
    margin: 0;
`

export const PBig = styled(P)`
    font-size: 1.25rem;
`

export const Span = styled.span`
    color: rgb(28, 33, 31);
    font-weight: 600;
    font-size: 3rem;
    @media (max-width: 1199px) {
        font-size: calc(15.27px + 2.73vw);
    }
`
