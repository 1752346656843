import React from 'react'
import { breakpoints } from './constants'

export const useMediaQuery = (m: string, defaultValue: boolean | null = null) => {
    if (typeof window === 'undefined') return defaultValue
    const mediaMatch = React.useMemo(() => matchMedia?.(m.replace('@media ', '')), [m])
    // SSR must return the same output at least ONCE
    const [matches, setMatches] = React.useState<boolean | null>(defaultValue)

    // Before rendering (again on SSR) we re-update the value
    React.useLayoutEffect(() => {
        // Take into account that it won't re-render if it didn't change
        setMatches(mediaMatch?.matches ?? false)
        const f = (e: MediaQueryListEvent) => {
            setMatches(e.matches)
        }
        if (!mediaMatch) return
        if (mediaMatch.addEventListener) {
            mediaMatch.addEventListener('change', f)
        } else {
            mediaMatch.addListener(f)
        }
        return () => {
            if (mediaMatch.removeEventListener) {
                mediaMatch.removeEventListener('change', f)
            } else {
                mediaMatch.removeListener(f)
            }
        }
    }, [m])
    return matches
}

export const useBreakpoints = () => {
    return {
        mobile: useMediaQuery(`(max-width: ${breakpoints.mobile}px)`),
        tablet: useMediaQuery(`(max-width: ${breakpoints.tablet}px)`),
        desktop: useMediaQuery(`(max-width: ${breakpoints.desktop}px)`),
        laptop: useMediaQuery(`(max-width: ${breakpoints.laptop}px)`),
    }
}

export const useDocumentScroll = ({
    autoRestore = true,
}: { autoRestore?: boolean } = {}) => {
    const scroll = {
        hide: () => {
            document.body.style.overflow = 'hidden'
        },
        restore: () => {
            document.body.style.overflow = ''
        },
    }
    const restoreRef = React.useRef(autoRestore)
    restoreRef.current = autoRestore
    React.useLayoutEffect(
        () => () => {
            if (restoreRef.current) scroll.restore()
        },
        [],
    )
    return scroll
}
