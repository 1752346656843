import { makeStyles } from '@material-ui/core'
import { breakpoints } from '../../constants'
import React from 'react'
import { useMediaQuery } from '../../hooks'
import { isFragment } from 'react-is'

const useStyles = makeStyles<any, { query: number }>((theme) => ({
    down: (props) => {
        return {
            [theme.breakpoints.down(props.query)]: {
                display: 'none !important',
            },
        }
    },
    up: (props) => {
        return {
            [theme.breakpoints.up(props.query)]: {
                display: 'none !important',
            },
        }
    },
}))

const cloneWithClassNameIgnoreFragments = (
    element: React.ReactElement,
    className: string,
    key?: string,
): React.ReactNode => {
    return React.Children.map(element, (child) => {
        if (React.isValidElement<any>(child)) {
            const props = child?.props
            if (isFragment(element)) {
                return cloneWithClassNameIgnoreFragments(props?.children, className, key)
            }
            return React.cloneElement(child, {
                key: props?.key ?? key,
                className: cls(props?.className, className),
            })
        }
        return null
    })
}

type Params = { up: string; down: string; ssr?: boolean; match?: boolean }
export type Options = {
    // render: it renders DOWN the specified breakpoint (default)
    // hide: it hides DOWN the specified breakpoint
    // handle-both: it renders DOWN and UP the specified breakpoint
    mode?: 'render' | 'hide' | 'handle-both'
}

export type Info = {
    className: string
    // it matches DOWN the specified breakpoint and should perform the action marked at {Options['mode']}
    matches: boolean
}

const render = (
    { up, down, ssr = false, match = false }: Params,
    elementFn: (info: Info) => React.ReactNode,
    { mode = 'render' }: Options = {},
) => {
    const shouldRender = mode === 'render'
    const className = shouldRender ? up : down
    const matches = {
        render: shouldRender,
        hide: !shouldRender,
        'handle-both': match,
    }[mode]

    const elementDown = elementFn({ className, matches })
    if (React.isValidElement(elementDown)) {
        if (mode === 'handle-both') {
            if (ssr) {
                // Render both on ssr
                const classNameUp = !shouldRender ? up : down
                const elementUp = elementFn({ className: classNameUp, matches: !matches })
                return (
                    <React.Fragment>
                        {cloneWithClassNameIgnoreFragments(elementDown, className)}
                        {React.isValidElement(elementUp) &&
                            cloneWithClassNameIgnoreFragments(elementUp, classNameUp)}
                    </React.Fragment>
                )
            } else {
                // Render whats actually visible
                const targetClassName = matches ? up : down
                const targetElement = elementFn({ className: targetClassName, matches })
                return (
                    <React.Fragment>
                        {React.isValidElement(targetElement) &&
                            cloneWithClassNameIgnoreFragments(
                                targetElement,
                                targetClassName,
                            )}
                    </React.Fragment>
                )
            }
        }

        if (ssr || shouldRender === match) {
            return (
                <React.Fragment>
                    {cloneWithClassNameIgnoreFragments(elementDown, className)}
                </React.Fragment>
            )
        }
    }

    return null
}

const cls = (...args: (string | undefined)[]) => args.filter(Boolean).join(' ')
export const useRenderQuery = (query: number) => {
    const { up, down } = useStyles({ query })
    const qry = useMediaQuery(`(max-width: ${query}px)`, null)
    return render.bind(null, {
        up,
        down,
        match: !!qry,
        ssr: typeof window === 'undefined' || qry === null,
    })
}

export const useRenderBreakpoints = () => {
    return {
        onTablet: useRenderQuery(breakpoints.tablet),
        onMobile: useRenderQuery(breakpoints.mobile),
        onLaptop: useRenderQuery(breakpoints.laptop),
        onDesktop: useRenderQuery(breakpoints.desktop),
    }
}
