import { css } from 'styled-components'

export const range = (n: number): number[] =>
    Array(n)
        .fill(0)
        .map((_, x) => x)

// Polyfill for gaps since they do not work on safari
export const columnGap = (gap: string | number) => {
    return css`
        & > * + * {
            margin-left: ${typeof gap === 'number' ? gap + 'px' : gap};
        }
    `
}
export const gridColumnGap = (gap: string | number, columns: number) => {
    return css`
        ${columnGap(gap)}
        & > *:nth-child(${columns}n + 1) {
            margin-left: 0;
        }
    `
}

export const gridRowGap = (gap: string | number, columns: number) => {
    return css`
        ${rowGap(gap)}
        & > *:nth-child(-n + ${columns}) {
            margin-top: 0;
        }
    `
}

export const gridGap = (gap: string | number, columns: number) => {
    return css`
        ${gridRowGap(gap, columns)}
        ${gridColumnGap(gap, columns)}
    `
}

// Polyfill for gaps since they do not work on safari
export const rowGap = (gap: string | number) => {
    return css`
        & > * + * {
            margin-top: ${typeof gap === 'number' ? gap + 'px' : gap};
        }
    `
}
// Polyfill for gaps since they do not work on safari
export const muiColumnGap = (gap: string | number) => {
    return {
        '& > * + *': {
            marginLeft: gap,
        },
    }
}
// Polyfill for gaps since they do not work on safari
export const muiRowGap = (gap: string | number) => {
    return {
        '& > * + *': {
            marginTop: gap,
        },
    }
}
